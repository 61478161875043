<template>
    <div id="homePageElement">

      <!-- ======= Top Bar ======= -->
      <section id="topbar" class="d-flex align-items-center">
        <div class="container d-flex justify-content-center justify-content-md-between">
          <div class="contact-info d-flex align-items-center">
            <i class="bi bi-envelope-fill"></i><a href="mailto:studioneb@studioneb.it">studioneb@studioneb.it</a>
            <i class="bi bi-phone-fill phone-icon"></i> +39 051 412 3740
          </div>
          <div class="social-links d-none d-md-block">
            <a href="https://www.facebook.com/studiotecniconeb" class="facebook"><i class="bi bi-facebook"></i></a>
            <a href="https://www.linkedin.com/company/studiotecniconeb/" class="linkedin"><i class="bi bi-linkedin"></i></a>
          </div>
        </div>
      </section><!-- End Top Bar -->

      <!-- ======= Header ======= -->
      <header id="header" class="d-flex align-items-center">
        <div class="container d-flex align-items-center justify-content-between">

          <h1 class="logo"><a href="#hero">Studio N&B</a></h1>
          <!-- Uncomment below if you prefer to use an image logo -->
          <!-- <a href="index.html" class="logo"><img src="assets/img/logo.png" alt="" class="img-fluid"></a>-->

          <nav id="navbar" class="navbar">
            <ul>
              <li><a class="nav-link scrollto active" href="#hero">Home</a></li>
              <li><a class="nav-link scrollto" href="#about">Chi siamo</a></li>
              <li><a class="nav-link scrollto" href="#services">Servizi</a></li>
              <li><a class="nav-link scrollto" href="#tools">Strumenti</a></li>
              <!-- <li><a class="nav-link scrollto" href="#sponsors">Sponsor</a></li> -->
              <li><a class="nav-link scrollto" href="#work-with-us">Lavora con noi</a></li>
              <li><a class="nav-link scrollto" href="#contact">Contatti</a></li>
            </ul>
            <i class="bi bi-list mobile-nav-toggle"></i>
          </nav>

        </div>
      </header><!-- End Header -->

    <!-- ======= Hero Section ======= -->
    <section id="hero" class="d-flex align-items-center">
      <div class="container position-relative" data-aos="fade-up" data-aos-delay="500">
        <h1>Studio Tecnico Meccanico</h1>
        <h2>Progettazione e disegno macchine automatiche</h2>
        <a href="#about" class="btn-get-started scrollto">Scopri di più</a>
      </div>
    </section><!-- End Hero -->

    <main id="main">

      <!-- ======= About Section ======= -->
      <section id="about" class="about">
        <div class="container">

          <div class="section-title">
            <span>Chi siamo</span>
            <h2>Chi siamo</h2>
          </div>

          <div class="row">
            <div class="col-lg-6 order-1 order-lg-2 about-img-container" data-aos="fade-left">
              <img src="../../public/assets/img/office/office7.jpg" alt="Office">
            </div>
            <div class="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content" data-aos="fade-right">
              <h3>Per packaging, consulenze tecniche, linee packaging, vari settori produttivi: alimentare, farmaceutico, cosmetico, tessile.</h3>
              <p class="fst-italic">
                Con oltre trent'anni di esperienza nel settore delle macchine automatiche per il packaging, abbiamo collaborato con aziende di ogni dimensione, dimostrando professionalità 
                e versatilità. Operiamo nei principali settori del packaging bolognese, offrendo soluzioni su misura per ogni tipo di macchina, dalle più veloci alle più economiche. 
                Forniamo consulenze che spaziano dalla progettazione di intere linee produttive a interventi specifici di ottimizzazione e aggiornamento, gestendo l'intera filiera, dalla 
                progettazione alla realizzazione.
              </p>
              <ul>
                <li><i class="bi bi-check-circle"></i> Lo Studio Tecnico N.& B. si è costituito nell'anno 2000, fondato da Mario Nicoletti e Patrick Bugalossi, due disegnatori progettisti con una precedente esperienza decennale nel settore delle macchine automatiche.</li>
                <li><i class="bi bi-check-circle"></i> I nostri clienti sono aziende che necessitano di una collaborazione esterna professionale e competente nella progettazione di macchine automatiche.</li>
                <li><i class="bi bi-check-circle"></i> La nostra storia ci ha portato a collaborare in diversi settori dell'automazione e ci ha permesso di acquisire ampie conoscenze tecniche.</li>
                <li><i class="bi bi-check-circle"></i> In questi anni abbiamo collaborato con aziende, tipicamente nel settore del packaging primario e secondario e dell'assemblaggio.</li>
              </ul>
            </div>
          </div>

        </div>
      </section><!-- End About Section -->

      <!-- ======= Clients Section ======= -->
      <section id="partners" class="clients">
        <div class="container" data-aos="zoom-in">

          <div class="section-title">
            <h2>Collaboratori</h2>
          </div>

          <div class="row d-flex align-items-center">

            <div class="col-lg-2 col-md-4 col-6">
              <img src="assets/img/partners/atlanta.png" class="img-fluid" alt="">
            </div>

            <div class="col-lg-2 col-md-4 col-6">
              <img src="assets/img/partners/cariba.png" class="img-fluid" alt="">
            </div>

            <div class="col-lg-2 col-md-4 col-6">
              <img src="assets/img/partners/sacmi.png" class="img-fluid" alt="">
            </div>

            <div class="col-lg-2 col-md-4 col-6">
              <img src="assets/img/partners/gd.png" class="img-fluid" alt="">
            </div>

            <div class="col-lg-2 col-md-4 col-6">
              <img src="assets/img/partners/manz.png" class="img-fluid" alt="">
            </div>

            <div class="col-lg-2 col-md-4 col-6">
              <img src="assets/img/partners/sasib.png" class="img-fluid" alt="">
            </div>

          </div>

        </div>
      </section><!-- End Clients Section -->

      <!-- ======= Services Section ======= -->
      <section id="services" class="services">
        <div class="container">

          <div class="section-title">
            <span>Servizi</span>
            <h2>Servizi</h2>
            <p>
              Ci proponiamo come società di servizi per tutte le attività necessarie di macchine automatiche alla definizione di un progetto meccanico completo.
            </p>  
            <br />    
            <p>
              Siamo in grado di fornire un servizio completo di definizione obiettivi, progettazione e disegno per lo sviluppo di macchine automatiche. Ci proponiamo inoltre per le altre attività inerenti, quali esecuzione di particolari, assiemi e distinte.
            </p>
          </div>

          <div class="row services-container">

            <div class="col-lg-6 col-md-6 d-flex align-items-stretch service-flex" data-aos="fade-up" data-aos-delay="450">
              <div class="icon-box">
                <div class="icon"><i class="bx bx-conversation"></i></div>
                <h4>Consulenza</h4>
                <p>Consulenza su linee di produzione con definizione di automazioni necessarie ai fini produttivi, automazioni, robot.</p>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 d-flex align-items-stretch service-flex" data-aos="fade-up">
              <div class="icon-box">
                <div class="icon"><i class="bx bx-line-chart"></i></div>
                <h4>Analisi</h4>
                <p>Analisi dei dati di progetto, valutazione soluzioni costruttive, calcolo e definizione degli obiettivi di progetto.</p>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 d-flex align-items-stretch service-flex" data-aos="fade-up" data-aos-delay="150">
              <div class="icon-box">
                <div class="icon"><i class="bx bx-wrench"></i></div>
                <h4>Progettazione</h4>
                <p>Esecuzione progetti con sistemi cad 2d/3d su specifiche del cliente.</p>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 d-flex align-items-stretch service-flex" data-aos="fade-up" data-aos-delay="300">
              <div class="icon-box">
                <div class="icon"><i class="bx bx-edit"></i></div>
                <h4>Disegni costruttivi</h4>
                <p>Esecuzioni di tutti gli elaborati necessari, lamiere, elementi strutturali e tavole di parti costruttive.</p>
              </div>
            </div>

          </div>

        </div>
      </section><!-- End Services Section -->

          <!-- ======= Projects Section ======= -->
      <section id="projects" class="clients">
        <div class="container" data-aos="zoom-in">

          <div class="section-title">
            <h2>Progetti</h2>
          </div>

          <div class="row d-flex align-items-center">

            <div class="col-lg-4 col-md-4 col-6">
              <img src="assets/img/machines/machine1.png" class="img-fluid" alt="">
            </div>

            <div class="col-lg-4 col-md-4 col-6">
              <img src="assets/img/machines/machine2.png" class="img-fluid" alt="">
            </div>

            <div class="col-lg-4 col-md-4 col-6">
              <img src="assets/img/machines/machine3.png" class="img-fluid" alt="">
            </div>

          </div>

        </div>
      </section><!-- End Projects Section -->

      <!-- ======= Tools Section ======= -->
      <section id="tools" class="tools">
        <div class="container">

          <div class="section-title">
            <span>Strumenti</span>
            <h2>Strumenti</h2>
            <p>Per svolgere la nostra attività utilizziamo i software CAD:</p>
          </div>

          <div class="container">
            <div class="row tools-container">

              <div class="col-12" data-aos="fade-up">
                <div class="box tools-box">
                  <span>01</span>
                  <h4>Creo Elements/Direct Modeling</h4>
                </div>
              </div>

              <div class="col-12" data-aos="fade-up" data-aos-delay="150">
                <div class="box tools-box">
                  <span>02</span>
                  <h4>Creo Parametric (Pro/Engineer)</h4>
                </div>
              </div>

              <div class="col-12" data-aos="fade-up" data-aos-delay="200">
                <div class="box tools-box">
                  <span>03</span>
                  <h4>Solid Edge</h4>
                </div>
              </div>

              <div class="col-12" data-aos="fade-up" data-aos-delay="250">
                <div class="box tools-box">
                  <span>04</span>
                  <h4>SolidWorks</h4>
                </div>
              </div>

            </div>
          </div>

          <div class="section-title" data-aos="fade-up">
            <p>Utilizziamo inoltre CAD 2D:</p>
          </div>

          <div class="container">
            <div class="row tools-container">

              <div class="col-12" data-aos="fade-up">
                <div class="box tools-box">
                  <span>01</span>
                  <h4>AutoCAD con applicativo QS-TOP</h4>
                </div>
              </div>

              <div class="col-12" data-aos="fade-up" data-aos-delay="150">
                <div class="box tools-box">
                  <span>02</span>
                  <h4>Cadra</h4>
                </div>
              </div>

              <div class="col-12" data-aos="fade-up" data-aos-delay="200">
                <div class="box tools-box">
                  <span>03</span>
                  <h4>Creo Elements/Direct Drafting (Me10)</h4>
                </div>
              </div>

              <div class="col-12" data-aos="fade-up" data-aos-delay="250">
                <div class="box tools-box">
                  <span>04</span>
                  <h4>Tell</h4>
                </div>
              </div>

            </div>
          </div>

        </div>
      </section>

      <!-- ======= Sponsors Section ======= -->
      <section id="sponsors" class="clients">
        <div class="container" data-aos="zoom-in">

          <div class="section-title">
            <h2>Finanziatori</h2>
          </div>       

          <div class="row d-flex align-items-center no-gutters">
  
            <div class="col-lg-3 col-md-4 col-6">
              <a href="https://eur-lex.europa.eu/browse/summaries.html" target="_blank">
                <img src="assets/img/sponsors/sponsor1.png" class="img-fluid sponsor-img" alt="Sponsor 1">
              </a>
            </div>

            <div class="col-lg-3 col-md-4 col-6">
              <a href="https://www.bolognappennino.it/" target="_blank">
                <img src="assets/img/sponsors/sponsor2.jpg" class="img-fluid sponsor-img" alt="Sponsor 2">
              </a>
            </div>

            <div class="col-lg-3 col-md-4 col-6">
              <a href="https://agricoltura.regione.emilia-romagna.it/psr-2014-2020" target="_blank">
                <img src="assets/img/sponsors/sponsor3.jpg" class="img-fluid sponsor-img" alt="Sponsor 3">
              </a>
            </div>

            <div class="col-lg-3 col-md-4 col-6">
                <img src="assets/img/sponsors/sponsor4.jpg" class="img-fluid sponsor-img" alt="Sponsor 4">
            </div>

          </div>


          <div class="learn-more-container">
            <p @click="toggleParagraph" class="learn-more-text">
              Scopri di più sui nostri finanziatori
            </p>
          
            <p v-if="showParagraph" class="sponsor-paragraph-text">
              La ditta N. & B. DI NICOLETTI MARIO E BUGALOSSI PATRICK - S.N.C. con sede legale in via VIA VIA MATTEOTTI, N. 5 MARZABOTTO (BO) ha partecipato
              al seguente Bando: REG. (UE) 1305/2013 – PSR 2014-2020 – Misura 19 – operazione 19.2.02 – az. 11A “Qualificazione delle piccole e micro imprese 
              extra-agricole esistenti nelle zone rurali per il mantenimento dei servizi commerciali, di pubblico esercizio di artigianato e dei servizi a favore 
              delle comunità locali e/o per l’attivazione di servizi/prodotti, anche innovativi, a supporto dello Sviluppo turistico – Leader”. Avviso Pubblico 
              2022 Terza Pubblicazione.
              Ha ricevuto il sostegno e l’approvazione del progetto su domanda AGREA n. 5555034 - N. & B. DI NICOLETTI MARIO E BUGALOSSI PATRICK - S.N.C. – CUAA
              02070511205 con la concessione di un contributo in conto capitale di euro 12.214,00 (in regime “de minimis” ai sensi del Reg. UE n. 1407/2013 della 
              Commissione del 18 dicembre 2013, relativo all’applicazione degli articoli 107 e 108 del Trattato sul funzionamento dell’Unione Europea pubblicato 
              in GU L.352 del 24/12/2013).
              Il finanziamento è stato utilizzato per l’acquisto di nuove attrezzature e arredi, utilizzati per la realizzazione di una nuova postazione di lavoro 
              presso la sede della ditta e tre postazioni portatili al fine di offrire ai clienti la consulenza/progettazione presso le loro sedi.
            </p>
          </div>   

        </div>
      </section><!-- End Sponsors Section -->

      <!-- ======= Work with us Section ======= -->
      <section id="work-with-us" class="contact">
        <div class="container">

          <div class="section-title">
            <span>Lavora con noi</span>
            <h2>Lavora con noi</h2>
            <p>Siamo alla ricerca di collaboratori.</p>
            <p>Se ti interessa lavorare con noi compila il form e inviacelo.</p>
          </div>

          <div class="row work-with-us-container" data-aos="fade-up">

            <div class="col-lg-6">
              <form @submit.prevent="sendEmail" class="php-email-form">
                <div class="form-group mt-3">
                  <input type="text" v-model="form.name" class="form-control" placeholder="Nome e cognome" required />
                </div>
                <div class="form-group mt-3">
                  <input type="email" v-model="form.email" class="form-control" placeholder="Email" required />
                </div>
                <div class="form-group mt-3">
                  <input type="text" v-model="form.address" class="form-control" placeholder="Indirizzo" required />
                </div>
                <div class="form-group mt-3">
                  <input type="text" v-model="form.phone" class="form-control" placeholder="Telefono" required />
                </div>
                <div class="form-group mt-3">
                  <select v-model="form.title" class="form-control form-select" required>
                    <option value="" disabled selected hidden>Titolo di studio</option>
                    <option value="liceo">Liceo</option>
                    <option value="tecnico">Istituto tecnico</option>
                    <option value="professionale">Istituto professionale</option>
                    <option value="triennale">Laurea triennale</option>
                    <option value="magistrale">Laurea magistrale</option>
                    <option value="altro">Altro</option>
                  </select>
                </div>
                <div class="form-group mt-3">
                  <textarea v-model="form.message" class="form-control" rows="5" placeholder="Messaggio"></textarea>
                </div>
                <div class="text-center">
                  <button id="submitapplication" type="submit">Invia</button>
                </div>
              </form>
            </div>

          </div>

        </div>
      </section><!-- End Work with us Section -->

      <!-- ======= Contact Section ======= -->
      <section id="contact" class="contact">
        <div class="container">

          <div class="section-title">
            <span>Contatti</span>
            <h2>Contatti</h2>
          </div>

          <div class="row" data-aos="fade-up">

            <div class="col-lg-6 ">
              <div class="row" data-aos="fade-up">

                <div class="col-lg-12">
                  <div class="info-box mb-4">
                    <i class="bx bx-map"></i>
                    <h3>Il nostro ufficio</h3>
                    <p>Via Matteotti 5, 40043 Marzabotto (BO) ITALY</p>
                  </div>
                </div>

                <div class="col-lg-6 col-md-6">
                  <div class="info-box  mb-4">
                    <i class="bx bx-envelope"></i>
                    <h3>Scrivici una mail</h3>
                    <p>studioneb@studioneb.it</p>
                  </div>
                </div>

                <div class="col-lg-6 col-md-6">
                  <div class="info-box  mb-4">
                    <i class="bx bx-phone-call"></i>
                    <h3>Chiamaci</h3>
                    <p>+39 051 412 3740</p>
                  </div>
                </div>

              </div>
          </div>

            <div class="col-lg-6 ">
              <iframe class="mb-4 mb-lg-0" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2853.4201961042986!2d11.204096015776878!3d44.34241851560701!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x132ad1a9dd53aa07%3A0xc3a33a89778b965b!2sVia%20Giacomo%20Matteotti%2C%205%2C%2040043%20Marzabotto%20BO!5e0!3m2!1sen!2sit!4v1656875406277!5m2!1sen!2sit" frameborder="0" style="border:0; width: 100%; height: 384px;" allowfullscreen></iframe>
            </div>

          </div>

        </div>
      </section><!-- End Contact Section -->

    </main><!-- End #main -->

    <!-- ======= Footer ======= -->
    <footer id="footer">
    <div class="footer-top">
      <div class="container">
        <div class="row footer-container">

          <div class="col-lg-4 col-md-6">
            <div class="footer-info">
              <h3>Studio N&B</h3>
              <p>
                Via Matteotti 5 <br>
                40043 Marzabotto (BO) ITALY<br><br>
                <strong>P.IVA e CF:</strong> 02070511205<br>
                <strong>Reg.Imprese Bologna n°</strong> 27133/2000<br>
                <strong>Albo artigiani Bo n°</strong> 124055<br>
              </p>
              <div class="social-links mt-3">
                <a href="https://www.facebook.com/studiotecniconeb" class="facebook"><i class="bx bxl-facebook"></i></a>
                <a href="https://www.linkedin.com/company/studiotecniconeb/" class="linkedin"><i class="bx bxl-linkedin"></i></a>
              </div>
            </div>
          </div>

          <div class="col-lg-2 col-md-6 footer-links">
            <h4>Link utili</h4>
            <ul>
              <li><i class="bx bx-chevron-right"></i> <a href="#hero">Home</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="#about">Chi siamo</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="#services">Servizi</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="#tools">Strumenti</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="#work-with-us">Lavora con noi</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="#contact">Contatti</a></li>
              <li><i class="bx bx-chevron-right"></i> <router-link to="/login">Accesso dipendenti</router-link></li>
            </ul>
          </div>

        </div>
      </div>
    </div>

    <div class="container">
      <div class="copyright">
        &copy; Copyright 2000 <strong><span>N.& B. S.r.l.</span></strong> All Rights Reserved
      </div>
      <div class="credits">
        <!-- All the links in the footer should remain intact. -->
        <!-- You can delete the links only if you purchased the pro version. -->
        <!-- Licensing information: https://bootstrapmade.com/license/ -->
        <!-- Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/day-multipurpose-html-template-for-free/ -->
        Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a>
      </div>
    </div>
    </footer><!-- End Footer -->

    <a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i class="bi bi-arrow-up-short"></i></a>
    <div v-if=!loaded id="preloader"></div>
  </div>
</template>

<script>
import AOS from "aos";
import emailjs from 'emailjs-com';

export default {
  name: 'HomePage',
  data() {
    return {
        loaded: false,
        showParagraph: false,
        form: {
          name: '',
          email: '',
          address: '',
          phone: '',
          title: '',
          message: ''
        }
    }
  },
  methods: {
    toggleParagraph() {
      this.showParagraph = !this.showParagraph; // Toggle paragraph visibility
    },
    sendEmail() {
      const serviceID = 'service_c9gh4mo';
      const templateID = 'template_625uhaz';
      const userID = 'ANtYeyBFHGKwQzQf8';

      // Send the email using EmailJS
      emailjs.send(serviceID, templateID, this.form, userID)
        .then(() => {
          alert('Il tuo messaggio è stato inviato. Grazie!');
        })
        .catch(() => {
          alert('Oops! Qualcosa è andato storto. Riprova più tardi o contattaci direttamente via email.');
        });
    }
  },
  mounted() {
    let Script = document.createElement("script");
    Script.setAttribute("src", "./assets/js/homepage.js");
    document.head.appendChild(Script);
    AOS.init();
    this.loaded = true;
  },
}
</script>

<style scoped>
.learn-more-text {
  cursor: pointer;
  text-decoration: underline;
  padding-top: 20px;
}

.sponsor-paragraph-text {
  margin-top: 10px;
}

/* Responsive padding adjustment for desktop */
@media (min-width: 768px) {
  .learn-more-text {
    padding-top: 40px; /* Increase padding for desktop */
  }
}

.row.no-gutters {
  margin-left: 0px;
  margin-right: 0px;
}

.row.no-gutters > [class*='col-'] {
  padding-left: 50px;
  padding-right: 50px;
}

img.img-fluid.sponsor-img {
  width: 100%; /* Ensures the image fills the container */
  height: 150px; /* Adjust this value as needed */
  object-fit: contain; /* Ensures the image scales without stretching */
}

#submitapplication {
  margin-top: 20px;
}

</style>