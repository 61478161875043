import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/LoginView.vue')
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('../views/DashboardView.vue')
  },
  {
    path: '/inserimento-ore',
    name: 'inserimento-ore',
    component: () => import('../views/HoursInsertionView.vue')
  },
  {
    path: '/aggiungi-commessa',
    name: 'aggiungi-commessa',
    component: () => import('../views/OrderCreationView.vue')
  },
  {
    path: '/crea-account',
    name: 'crea-account',
    component: () => import('../views/UserCreationView.vue')
  },
  {
    path: '/ore-commesse',
    name: 'ore-commesse',
    component: () => import('../views/OrdersHoursView.vue')
  },
  {
    path: '/ore-dipendenti',
    name: 'ore-dipendenti',
    component: () => import('../views/EmployeesHoursView.vue')
  },
  {
    path: '/:catchAll(.*)',
    name: 'error',
    component: () => import('../views/ErrorView.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
