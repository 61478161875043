import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'aos/dist/aos.css'
import DataTable from 'datatables.net-vue3'
import DataTablesLib from 'datatables.net';
import DatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
 
DataTable.use(DataTablesLib);

const firebaseConfig = {
    apiKey: "AIzaSyCc3IGhl-PTlsQI-nPF09N1yrGbFAKuzU0",
    authDomain: "studio-neb.firebaseapp.com",
    projectId: "studio-neb",
    storageBucket: "studio-neb.appspot.com",
    messagingSenderId: "151191332921",
    appId: "1:151191332921:web:c4820ecb9d01c01c6a45e8",
    measurementId: "G-THP96HDBN2"
};  

// Initialize Firebase
const firebaseApp = firebase.initializeApp(firebaseConfig);
// Use these for db & auth
const db = firebaseApp.firestore();
const auth = firebase.auth();
export { auth, db };

const app = createApp(App);

app.component('DatePicker', DatePicker);

app.use(router);

app.mount('#app');

